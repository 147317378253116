<template>
  <form-consulta-cadastro
    :formCadastro="formCadastro"
    :controller="controller"
  />
</template>

<script>
import { ConsultaAtividadesProjeto } from '@/proxy-api/src/services/classes/Projeto/ConsultaAtividadesProjeto';

import FormConsultaCadastro from '@/components/form/FormConsultaCadastro';
import FormAtividadeProjeto from '@/views/atividadeProjeto/form/FormAtividadeProjeto';

export default {
  components: { FormConsultaCadastro },

  data: function () {
    return {
      controller: new ConsultaAtividadesProjeto(),
      formCadastro: FormAtividadeProjeto,
    };
  },
};
</script>
